import React, { useState } from 'react';
import { Button } from '@mui/material';
import './Card.css'
export function Card() {
    const [buttonEven, setButtonEven] = useState('homepage');
    const [chapter, setChapter] = useState('');
    const [verse, setVerse] = useState('');
    const [message, setMessage] = useState('');

    let displayedComponent;
    const sendRequestToGetVerse = async () => {
        const url = 'https://onlinechurch.us.kg/verse';
        const headers = {
            'Content-Type': 'application/json',
        };

        const body = JSON.stringify({
            name: 'John Doe',
            email: 'john@example.com',
            phone: '123-456-7890',
            message: 'This is a test message.',
        });

        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: headers,
                body: body,
            });

            if (!response.ok) {
                throw new Error('Request failed!');
            }

            const data = await response.json();

            setChapter(data.chapter);
            setVerse(data.verse);
            setMessage(data.message);
        } catch (error) {
            console.error('Error:', error);
        }
    };
    const handleGetFathersCard = () => {
        sendRequestToGetVerse()
        setButtonEven('pushed')
        console.log(chapter)
        console.log(verse)
        console.log(message)
    };
    switch (buttonEven) {
        case 'pushed':
            displayedComponent =
                <div className='show-verse-container'>
                    <div>{chapter}</div>
                    <p></p>
                    <div>{verse}</div>
                    <p></p>
                    <div>天父想表達的話:</div>
                    <div>{message}</div>
                </div>;
            break;
        default:
            displayedComponent = <Button
                sx={{
                    fontSize: '30px', // 字體大小
                    padding: '10px 20px', // 內邊距
                    borderRadius: '8px', // 圓角邊框
                    '&:hover': {
                        backgroundColor: 'rgba(215, 226, 211, 0.944)', // 滑鼠懸停時的背景顏色
                    },
                    zIndex: 2, // 設置 z-index
                }}
                onClick={handleGetFathersCard}
            >
                抽天父小卡
            </Button>
            break;
    }

    return (
        <div className='card-container'>
            {displayedComponent}
        </div>
    )
}