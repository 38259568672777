import './App.css';
import { Church } from './Church'
function App() {
  return (
    <>
      <Church />
    </>
  );
}

export default App;