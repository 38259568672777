
import React, { useState } from 'react';
import './HomepageWeb.css';
import { Button } from '@mui/material';
import { Contact } from '../contact/Contact';
import { Card } from '../card/Card';

export function HomepageWeb() {
    const [buttonEven, setButtonEven] = useState('');

    const handler_button_cards = () => {
        setButtonEven('cards')
    };
    const handler_button_equip = () => {
        setButtonEven('equip')
    };
    const handler_button_music_sheet = () => {
        setButtonEven('music_sheet')
    };
    const handler_button_contribution = () => {
        setButtonEven('contribution')
    };
    const handler_button_contact = () => {
        setButtonEven('contact')
    };

    let displayedComponent;

    switch (buttonEven) {
        case 'cards':
            displayedComponent = <Card />;
            break;
        case 'equip':
            displayedComponent = <div></div>;
            break;
        case 'music_sheet':
            displayedComponent = <div></div>;
            break;
        case 'contribution':
            displayedComponent = <div></div>;
            break;
        case 'contact':
            displayedComponent = <Contact />;
            break;
        default:
            displayedComponent = <div></div>;
            break;
    }
    return (
        <div>
            <div className="scripture-background-container">
                <div className="button-container">
                    <Button className="transparent-button"
                        onClick={handler_button_cards}>天父小卡</Button>
                    <Button className="transparent-button"
                        onClick={handler_button_equip}>裝備課程</Button>
                    <Button className="transparent-button"
                        onClick={handler_button_music_sheet}>司琴樂譜</Button>
                    <Button className="transparent-button"
                        onClick={handler_button_contribution}>線上奉獻</Button>
                    <Button className="transparent-button"
                        onClick={handler_button_contact}>聯絡我們</Button>
                </div>
                <div className="display-container">
                    {displayedComponent}
                </div>
            </div>
        </div>
    )
}