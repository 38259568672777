
import React, { useState } from 'react';
import './HomepagePhone.css';
import { TextField, Card, CardContent, Button, Box, Typography, Divider } from '@mui/material';
import { IconButton } from '@mui/material';
import House from '@mui/icons-material/House';
import LibraryMusic from '@mui/icons-material/LibraryMusic';
import Quiz from '@mui/icons-material/Quiz';
import BatteryChargingFull from '@mui/icons-material/BatteryChargingFull';
import AttachMoney from '@mui/icons-material/AttachMoney';
import ContactMail from '@mui/icons-material/ContactMail';
import Reply from '@mui/icons-material/Reply';


function Homepage() {
    return (
        <Typography variant="h4"
            sx={{
                textAlign: 'center',
                marginTop: '3vh',// 距離頂端 10% 高度 
                color: '#9ccbed',
            }}
            gutterBottom>首頁</Typography>
    )
}
function ScriptureButton({ setButtonEven }) {

    const handler_button_cards = () => {
        setButtonEven('scriptureCard')
    };
    return (
        <div>
            <Typography variant="h4"
                sx={{
                    textAlign: 'center',
                    marginTop: '3vh',// 距離頂端 10% 高度 
                    color: '#9ccbed',
                }}
                gutterBottom>天父小卡</Typography>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',  // 設置為縱向排列
                    alignItems: 'center',     // 水平居中
                    justifyContent: 'center', // 垂直居中
                    marginTop: '20vh',// 距離頂端 10% 高度 
                }}
            >
                <Button
                    variant="contained"
                    sx={{
                        color: 'white',            // 文字顏色設為白色
                        fontSize: '1.5rem',        // 字體大小設為 1.5rem
                        padding: '10px 20px',      // 按鈕內邊距，增加按鈕的寬度和高度
                        marginTop: '3vh',          // 按鈕距離頂端 3% 高度
                        '&:hover': {
                            backgroundColor: '#0097a7', // hover 時的顏色
                        },
                        transition: 'background-color 0.3s ease', // 平滑過渡效果
                    }}
                    onClick={handler_button_cards}
                >
                    抽取天父小卡
                </Button>
            </Box>

        </div>
    )
}
function ScriptureCard({ setButtonEven }) {
    const handler_button_cards = () => {
        setButtonEven('scriptureButton')
    };
    const chapter = localStorage.getItem('chapter');
    const verse = localStorage.getItem('verse');
    const message = localStorage.getItem('message');
    return (
        <div>

            <Typography variant="h4"
                sx={{
                    textAlign: 'center',
                    marginTop: '3vh',// 距離頂端 10% 高度 
                    color: '#9ccbed',
                }}
                gutterBottom>天父小卡</Typography>

            <Box
                sx={{
                    width: '100%',
                    marginTop: '8vh',// 距離頂端 10% 高度
                }}>
                {/* 章節 */}
                <Typography variant="h4" gutterBottom sx={{ textAlign: 'center', color: '#3f51b5' }}>
                    {chapter}
                </Typography>

                {/* 經文 */}
                <Typography variant="h6"
                    sx={{
                        fontStyle: 'italic',    // 文字斜體
                        textAlign: 'center',    // 文字居中
                        paddingLeft: '10px',    // 左邊縮排 10px
                        paddingRight: '10px',   // 右邊縮排 10px
                    }}
                    gutterBottom>
                    "{verse}"
                </Typography>

                <Divider sx={{ margin: '20px 0' }} />

                {/* 天父想說的話 */}
                <Card sx={{
                    width: '100%',               // 預設寬度 100%
                    maxWidth: 500,               // 最大寬度 500px
                    margin: 'auto',              // 使 Card 在畫面中居中
                    padding: 2,                  // 內邊距
                    boxSizing: 'border-box',     // 包含邊框的尺寸
                    backgroundColor: '#6eb0e2',  // 水藍色背景
                    // 響應式設置
                    "@media (max-width:600px)": { // 小於 600px 的螢幕
                        maxWidth: '90%',          // 使用 90% 寬度
                        padding: 1,               // 減少內邊距
                    },
                    "@media (min-width:600px) and (max-width:960px)": { // 介於 600px 和 960px 的螢幕
                        maxWidth: '80%',          // 使用 80% 寬度
                    },
                    "@media (min-width:960px)": { // 大於 960px 的螢幕
                        maxWidth: 500,            // 使用最大寬度 500px
                    }
                }}>
                    <CardContent>
                        <Typography variant="h5" gutterBottom>
                            天父想對你說的話：
                        </Typography>
                        <Typography variant="body1" color="textSecondary">
                            {message}
                        </Typography>
                    </CardContent>
                </Card>
            </Box>
            {/* <Typography variant="h6"
                sx={{
                    fontStyle: 'italic',    // 文字斜體
                    textAlign: 'center',    // 文字居中
                    paddingLeft: '10px',    // 左邊縮排 10px
                    paddingRight: '10px',   // 右邊縮排 10px
                    marginTop: '8vh',// 距離頂端 10% 高度
                }}
                gutterBottom>
                一天可抽一次，今天已經抽過了喔
            </Typography> */}
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',  // 設置為縱向排列
                    alignItems: 'center',     // 水平居中
                    justifyContent: 'center', // 垂直居中
                    marginTop: '10vh',// 距離頂端 10% 高度 
                }}
            >
                <Button
                    variant="contained"
                    sx={{
                        color: 'white',            // 文字顏色設為白色
                        fontSize: '1.5rem',        // 字體大小設為 1.5rem
                        padding: '10px 20px',      // 按鈕內邊距，增加按鈕的寬度和高度
                        marginTop: '3vh',          // 按鈕距離頂端 3% 高度
                        '&:hover': {
                            backgroundColor: '#0097a7', // hover 時的顏色
                        },
                        transition: 'background-color 0.3s ease', // 平滑過渡效果
                    }}
                    onClick={handler_button_cards}
                >
                    再抽一次
                </Button>
            </Box>

        </div>
    );
};
function Equip() {
    return (
        <Typography variant="h4"
            sx={{
                textAlign: 'center',
                marginTop: '3vh',// 距離頂端 10% 高度 
                color: '#9ccbed',
            }}
            gutterBottom>裝備課程</Typography>
    )
}
function MushicSheet() {
    return (
        <Typography variant="h4"
            sx={{
                textAlign: 'center',
                marginTop: '3vh',// 距離頂端 10% 高度 
                color: '#9ccbed',
            }}
            gutterBottom>司琴樂譜</Typography>
    )
}
function Contribution() {
    return (
        <Typography variant="h4"
            sx={{
                textAlign: 'center',
                marginTop: '3vh',// 距離頂端 10% 高度 
                color: '#9ccbed',
            }}
            gutterBottom>線上奉獻</Typography>
    )
}
function Contact() {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        message: ''
    });

    const [messageLength, setMessageLength] = useState(0);

    // 處理輸入改變
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });

        // 如果是留言欄位，限制字數
        if (name === 'message') {
            setMessageLength(value.length);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // 提交表單邏輯（例如，發送 API 請求等）
        console.log(formData);
    };
    return (
        <div>
            <Typography variant="h4"
                sx={{
                    textAlign: 'center',
                    marginTop: '3vh',// 距離頂端 10% 高度 
                    color: '#9ccbed',
                }}
                gutterBottom>聯絡我們</Typography>
            <Box component="form" onSubmit={handleSubmit}
                className="contact-form-container">

                {/* 姓名欄位 */}
                <TextField
                    className="text-form"
                    label="名字"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                />

                {/* 電子郵件欄位 */}
                <TextField
                    className="text-form"
                    label="電子郵箱"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    type="email"
                />

                {/* 聯絡電話欄位 */}
                <TextField
                    className="text-form"
                    label="聯絡電話"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    name="phone"
                    value={formData.phone}
                    onChange={handleInputChange}
                    type="tel"
                />

                {/* 留言欄位 */}
                <TextField
                    className="text-form"  // 使用CSS類
                    label="留言"
                    variant="outlined"
                    fullWidth
                    multiline
                    rows={4}
                    name="message"
                    value={formData.message}
                    onChange={handleInputChange}
                    inputProps={{ maxLength: 1000 }}
                    helperText={`${messageLength}/1000 字`}
                    margin="normal"
                />

                {/* 提交按鈕 */}
                <Box sx={{ textAlign: 'center', marginTop: 2 }}>
                    <Button type="submit" variant="contained" color="primary">
                        提交
                    </Button>
                </Box>
            </Box>
        </div>

    )
}
export function HomepagePhone() {
    const [buttonEven, setButtonEven] = useState('');

    const handler_get_scripture_button = () => {
        setButtonEven('scriptureButton')
    };
    const handler_button_equip = () => {
        setButtonEven('equip')
    };
    const handler_button_music_sheet = () => {
        setButtonEven('music_sheet')
    };
    const handler_button_contribution = () => {
        setButtonEven('contribution')
    };
    const handler_button_contact = () => {
        setButtonEven('contact')
    };
    const handler_go_previous_button = () => {
        setButtonEven('homepage')
    };
    const handler_go_home_button = () => {
        setButtonEven('homepage')
    };
    const fetchAndStoreChapter = () => {
        const requestBody = {
            key: 'value', // 這是你希望發送的資料
        };

        fetch('https://onlinechurch.us.kg/verse', {
            method: 'POST', // 設置為 POST 請求
            headers: {
                'Content-Type': 'application/json', // 設定資料為 JSON 格式
            },
            body: JSON.stringify(requestBody), // 將 body 轉換為 JSON 字串
        })
            .then((response) => response.json())  // 解析返回的 JSON
            .then((data) => {
                const chapterData = data.chapter;
                const verseDate = data.verse;
                const messageDate = data.message;
                localStorage.setItem('chapter', chapterData);
                localStorage.setItem('verse', verseDate);
                localStorage.setItem('message', messageDate);
            })
            .catch((error) => {
                console.error('錯誤發生:', error);  // 捕獲並顯示錯誤
            });
    };
    let displayedComponent;

    switch (buttonEven) {
        case 'scriptureButton':
            fetchAndStoreChapter()
            displayedComponent = <ScriptureButton
                setButtonEven={setButtonEven} />;
            break;
        case 'scriptureCard':
            fetchAndStoreChapter()
            displayedComponent = <ScriptureCard
                setButtonEven={setButtonEven} />;
            break;
        case 'equip':
            displayedComponent = <Equip />;
            break;
        case 'music_sheet':
            displayedComponent = <MushicSheet />;
            break;
        case 'contribution':
            displayedComponent = <Contribution />;
            break;
        case 'contact':
            displayedComponent = <Contact />;
            break;
        default:
            displayedComponent = <Homepage />;
            break;
    }
    return (
        <div className="app-container">
            <div className="top-buttons-container">
                <IconButton onClick={handler_get_scripture_button}><Quiz /></IconButton>
                <IconButton onClick={handler_button_equip}><BatteryChargingFull
                /></IconButton>
                <IconButton onClick={handler_button_music_sheet}><LibraryMusic /></IconButton>
                <IconButton onClick={handler_button_contribution}><AttachMoney /></IconButton>
                <IconButton onClick={handler_button_contact}><ContactMail /></IconButton>
            </div>

            <div className="content">
                <div className="scrollable-content">
                    {displayedComponent}
                </div>
            </div>

            {/* 最底層按鈕 */}
            <div className="bottom-buttons">
                <IconButton
                    onClick={handler_go_home_button}><House /></IconButton>
                <IconButton
                    onClick={handler_go_previous_button}><Reply /></IconButton>
            </div>
        </div>
    )
}