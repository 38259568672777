
import React, { useState, useEffect } from 'react';
import './Church.css';
import { HomepagePhone } from './phone/HomepagePhone';
import { HomepageWeb } from './website/HomepageWeb';


export function Church() {

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    // 設定偵測螢幕尺寸變化的效應
    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    // 根據視窗寬度來渲染不同內容
    return (
        <div>
            {windowWidth >= 1024 ? (
                <HomepageWeb />
            ) : (
                <HomepagePhone />
            )}
        </div>
    );
}
